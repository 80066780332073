import * as customDict from '@/config/dict'

export default {
  'user_info': {},
  'dictList': filterCustomDict(customDict),
  'menu/data': [],
}

function filterCustomDict(customDict) {
  const dicts = []
  const list = Object.keys(customDict)
  list.forEach(item => {
    if (Array.isArray(customDict[item])) {
      dicts.push({
        name: '自定义属性',
        key: item + 'Dict',
        items: customDict[item],
      })
    }
  })

  return dicts
}
