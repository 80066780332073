/*
 * @Author: jiang
 * @Date: 2021-07-18 09:44:42
 * @Description: 自定义字典
 */

/**
 * 弹窗类型
 */
export const dialogType = [
  { label: '新建', value: 'create' },
  { label: '修改', value: 'update' },
  { label: '详情', value: 'info' },
]
/**
 * 接口申请状态
 */
export const approveStatus = [
  { label: '待审批', value: '0', desc: '', show: true },
  { label: '通过', value: '1', desc: '' },
  { label: '驳回', value: '2', desc: '' },
]

/**
 * 用户审批状态
 */
export const userAuditStatus = [
  { label: '待审批', value: '0', desc: '', show: true },
  { label: '驳回', value: '1', desc: '' },
  { label: '通过', value: '2', desc: '' },
]

export const stateColorMap = {
  已完成: '#91cc75',
  进行中: '#fac858',
  已逾期: '#ee6666',
  超时完成: '#91cc75',
}

export const attendanceType = [
  { label: '调训', value: 'diaoxun' },
  { label: '离岗', value: 'ligang' },
  { label: '外聘', value: 'waipin' },
  { label: '请假', value: 'leave' },
  { label: '出国', value: 'abroad' },
]

export const customTaskStatus = [
  { label: '进行中任务', value: 'progress' },
  { label: '逾期的任务', value: 'overdue' },
  { label: '收到的任务', value: 'received' },
  { label: '已完成的任务', value: 'practicable' },
  { label: '发布的任务', value: 'release' },
]

/**
 * 项目监管 预警状态
 */
export const projectWarningStatus = [
  { label: '正常', value: '0' },
  { label: '橙色预警', value: '1' },
  { label: '黄色预警', value: '2' },
]

/**
 * 项目监管 建设状态
 */
export const projectBuildStatus = [
  { label: '在建', value: '1' },
  { label: '待验收', value: '2' },
]

/**
 * 项目监管 度数据填报完成状态
 */
export const projectFillInStatus = [
  { label: '未完成', value: '0' },
  { label: '已完成', value: '1' },
]

/**
 * 领导工作周报审核状态
 */
export const reportAuditStatus = [
  { label: '待审核', value: 'not_examine' },
  { label: '通过', value: 'pass' },
  { label: '未通过', value: 'no_pass' },
]
