/*
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-03 09:10:42
 * @listEditTime: Do not edit
 */
import Vue from 'vue'
import Vuex from 'vuex'
import nProgress from '@/plugin/n-progress'
import { getLocal, setLocal } from '@/store/storage'
import defaultStorage from './defaults'
import user from './modules/user'
import tag from './modules/tag'
import menuModule from './modules/menu'
import imgTextList from './modules/imgList'
import schema from "./modules/schema"

import { indexPage } from '@/config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    schema,
    user,
    tag,
    menu: menuModule,
    imgTextList
  },
  state: {
    dictList: getLocal('dictList'),
    loadingCount: 0,
    indexPage: indexPage,
  },
  getters: {},
  mutations: {
    LOADING_START(state) {
      if (state.loadingCount === 0) {
        nProgress.start()
      }
      state.loadingCount++
    },
    LOADING_DONE(state) {
      state.loadingCount--
      if (state.loadingCount <= 0) {
        state.loadingCount = 0
        nProgress.done()
      }
    },
    SET_DICT(state, data) {
      const list = [...data, ...defaultStorage.dictList]
      state.dictList = list
      setLocal('dictList', list)
    },
  },
  actions: {},
})
