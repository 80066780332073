<template>
  <div class="c-empty">
    <div class="g-center">
      <div class="info-box">
        <div class="title">抱歉</div>
        <div class="desc">抱歉当前页面无内容，您可以点击刷新。</div>
        <el-button
          class="btn"
          type="primary"
          @click="onRefresh"
        >点击刷新</el-button>
      </div>
      <img
        class="image"
        src="@/assets/images/empty.png"
        alt="empty"
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onRefresh() {
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0;

  .info-box {
    .title {
      margin-bottom: 20px;
      font-size: 50px;
      font-weight: bold;
      line-height: 1;
      color: #04a37e;
    }

    .desc {
      font-size: 14px;
      color: #2b2d42;
    }

    .btn {
      margin-top: 30px;
    }
  }

  .image {
    width: 400px;
    height: 240px;
    object-fit: contain;
    margin-left: 30px;
  }
}
</style>
