<!--
 * @Author: jiang
 * @Date: 2021-07-28 21:30:14
 * @Description:
-->
<template>
  <app-menu-horizontal></app-menu-horizontal>
</template>

<script>
import AppMenuHorizontal from './app-menu-horizontal.vue'
export default {
  components: {
    AppMenuHorizontal,
  },
  data() {
    return {}
  },
}
</script>
