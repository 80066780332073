<!--
 * @Author: jiang
 * @Date: 2021-05-27 11:58:07
 * @Description: 通用表单

  option
    -
      title: string
      key: string default
      span: number,
      formAttrs: object,

      children:
        -
          type: string [input | select | ]
          prop: string
          label: string
          span: number,
          rules: array
          slot: boolean
          formItemAttrs:
          inputAttrs:
          inputSlots:
          inputEvents:
          selectOptions:
          selectAttrs:
          selectEvents:
-->
<template>

  <el-form
    ref="form"
    :model="formData"
    v-bind="option.formAttrs"
  >
    <div class="b-form__Header">
      <slot name="formHeader"></slot>
    </div>
    <section
      class="b-form__section"
      v-for="sectionItem in sectionList"
      :key="sectionItem.key"
    >
      <!-- 模块 - 标题 -->
      <div
        class="b-form__section-header"
        v-if="sectionItem.title"
      >{{ sectionItem.title }}</div>
      <!-- 模块内容 -->
      <div class="b-form__section-body">
        <el-row v-bind="sectionItem.rowAttrs">
          <el-col
            v-for="item in sectionItem.children"
            :key="item.prop"
            :span="item.formSpan"
          >
            <el-form-item
              :label="item.label"
              :prop="item.prop"
              :rules="item.rules"
            >
              <template v-if="item.formSlot === true">
                <slot
                  :name="handlerCapitalizeFirst('formItem', item.prop)"
                  :formData="formData"
                ></slot>
              </template>
              <!-- radio -->
              <template v-else-if="item.type === 'radio'">
                <el-radio-group v-model="formData[item.prop]">
                  <el-radio
                    v-for="option in item.options"
                    :key="option.value"
                    :label="item.value"
                  >{{ option.label }}</el-radio>
                </el-radio-group>
              </template>

              <!-- select -->
              <template v-else-if="item.type === 'select'">
                <el-select
                  v-model="formData[item.prop]"
                  v-bind="item.selectAttrs"
                  v-on="item.selectEvents"
                >
                  <el-option
                    v-for="selectOption in item.selectOptions"
                    :key="selectOption.value"
                    :label="selectOption.label"
                    :value="selectOption.value"
                  ></el-option>
                </el-select>
              </template>

              <!-- element date-picker -->
              <template v-else-if="item.type === 'date'">
                <el-date-picker
                  v-model="formData[item.prop]"
                  v-bind="item.dateAttrs"
                  v-on="item.dateEvents"
                ></el-date-picker>
              </template>

              <!-- element cascader-->
              <template v-else-if="item.type === 'cascader'">
                <el-cascader
                  v-model="formData[item.prop]"
                  :options="options"
                ></el-cascader>
              </template>

              <!-- input v-model 的 .trim 和 .number -->
              <template v-else-if="item.type === 'input' || item.type === undefined ">
                <el-input
                  v-model="formData[item.prop]"
                  v-bind="item.inputAttrs"
                  v-on="item.inputEvents"
                >
                  <template
                    v-for="inputSlot in item.inputSlots"
                    :slot="inputSlot"
                  >
                    <slot
                      :name="handlerCapitalizeFirst('formItem', item.prop, 'input', inputSlot)"
                      v-bind="formData"
                    >
                    </slot>
                  </template>
                </el-input>
              </template>

            </el-form-item>
          </el-col>
          <el-col v-if="sectionItem.slot">
            <slot :name="handlerCapitalizeFirst('formLayout', sectionItem.key)"></slot>
          </el-col>
        </el-row>
      </div>
    </section>
    <div class="b-form__footer">
      <slot name="formFooter"></slot>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    /**
     * 配置数据 转化合并
     * type: '' default: input
     */
    formItems() {
      return this.option.columns
    },
    sectionList() {
      const option = this.$tools.copy(this.option)
      const layouts = option.formLayouts || []
      let defaultLayout = layouts.find((item) => item.key === 'default')
      if (!defaultLayout) {
        defaultLayout = { key: 'default' }
        layouts.unshift(defaultLayout)
      }

      option.columns.forEach((column) => {
        if (column.showForm !== false) {
          let layout = layouts.find((item) => item.key === column.layoutKey)
          if (!layout) {
            layout = defaultLayout
          }
          if (!layout.children) {
            layout.children = []
          }

          layout.children.push(column)
        }
      })

      // console.log(layouts)
      return layouts
    },
  },
  watch: {
    data: {
      handler(val) {
        // console.log('val', val)
        if (val) {
          this.formData = this.$tools.copy(val)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    reset() {
      // this.$refs.form.resetFields()
    },
    valid() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid, err) => {
          if (valid) {
            resolve(this.formData)
          } else {
            reject(err)
          }
        })
      })
    },
    getForm() {
      return this.$refs.form
    },
    getData() {
      return this.formData
    },
    handlerCapitalizeFirst(pre, ...strArr) {
      let value = pre
      strArr.forEach((item) => {
        value += item.replace(item[0], item[0].toUpperCase())
      })
      return value
    },
  },
}
</script>

<style lang="scss" scoped>
.b-form__section {
  margin-bottom: 20px;

  .b-form__section-body {
    padding: 10px 30px;
  }

  .b-form__section-header {
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    line-height: 40px;
    color: #000;
    background: #ecebeb;

    + .b-form__section-body {
      border: 1px solid #ecebeb;
      box-shadow: 2px 2px 5px 0 #eee;
    }
  }

  // reset
  .el-date-editor {
    width: 100%;
  }

  .el-cascader {
    display: block;
  }
}
</style>
