<!--
 * @Author: jiang
 * @Date: 2021-06-10 18:26:26
 * @Description: 页面标签
-->
<template>
  <div
    class="page-tag-container"
    v-if="tagList && tagList.length"
  >
    <div class="page-tag-box">
      <el-tabs
        type="card"
        v-model="activeTab"
        @tab-remove="onTabRemove"
      >
        <el-tab-pane
          v-for="tag in tagList"
          :key="tag.path"
          :closable="tag.path !== '/'"
          :label="tag.label"
          :name="tag.path"
        ></el-tab-pane>
      </el-tabs>
    </div>

    <!-- <el-dropdown>
      <el-button>
        更多菜单<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>黄金糕</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'a',
    }
  },
  computed: {
    tagList() {
      return this.$store.state.tag.tagList
    },
  },
  watch: {
    '$route.path': {
      handler(path) {
        this.activeTab = path
      },
      immediate: true,
    },
    'activeTab'(activeTab) {
      this.handlerNav(activeTab)
    },
  },
  methods: {
    handlerNav(activeTab) {
      console.log(activeTab)
      if (this.$route.path === activeTab) return
      const tag = this.tagList.find(item => item.path === activeTab)
      if (tag) {
        this.$router.push({ path: tag.path, params: tag.params, query: tag?.query })
      }
    },
    onTabRemove(targetName) {
      // console.log(targetName)
      this.$store.commit('tag/REMOVE', targetName)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-tag-container {
  display: flex;
  padding: 10px;

  .page-tag-box {
    flex: 1;
    min-width: 0;
  }

  /deep/ .el-tabs--card > .el-tabs__header {
    margin: 0;
    border: none;

    .el-tabs__nav {
      border: none;
    }

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: 30px;
    }

    .el-tabs__item {
      height: 30px;
      margin: 0 3px;
      line-height: 30px;
      color: #747e8f;
      background-color: #fff;
      border: 1px solid #e4e7ed;
      border-radius: 3px;

      &.is-active {
        color: #fff;
        background-color: $color-primary;
      }
    }
  }
}
</style>
