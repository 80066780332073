/*
 * @Author: jiang
 * @Date: 2021-06-25 22:43:09
 * @Description: 扩展vue原型
 */
import Vue from 'vue'
import request from '@/api/axios'
import echarts from './echarts'
import tools from '@/utils/tools'

Vue.prototype.$axios = request
Vue.prototype.$tools = tools
Vue.prototype.$echarts = echarts
