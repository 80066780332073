/*
 * @Author: jiang
 * @Date: 2021-06-10 21:16:21
 * @Description: 页面标签
 */

// import { getLocal, setLocal, getSession, setSession, clearStorage } from '@/store/storage'
import router from '@/router/index'

const tag = {
  namespaced: true,
  state: {
    tagList: [
      {
        label: '系统首页',
        path: '/',
        params: {},
        query: {},
      },
    ],
  },
  mutations: {
    ADD_TAG: (state, tag) => {
      const flag = state.tagList.find(item => item.path === tag.path)
      if (!flag) {
        let index = state.tagList.findIndex(item => item.path === router.currentRoute.path)
        if (index === -1) {
          index = 0
        }
        state.tagList.splice(index + 1, 0, tag)
      }
    },
    REMOVE: (state, path) => {
      const index = state.tagList.findIndex(item => item.path === path)

      const currentIndex = state.tagList.findIndex(item => item.path === router.currentRoute.path)

      if (index > -1) {
        state.tagList.splice(index, 1)

        if (index <= currentIndex) {
          const tag = state.tagList[currentIndex - 1]
          router.push({ path: tag.path, params: tag.params, query: tag?.query })
        }
      }
    },
    CLEAR_TAG: state => {
      state.tagList = [
        {
          label: '系统首页',
          path: '/',
          params: {},
          query: {},
        },
      ]
    },
  },
}
export default tag
