/*
 * @Author: jiang
 * @Date: 2021-06-11 11:07:31
 * @Description: 全局组件
 */
import Vue from 'vue'
import BPage from '@/components/section/page'
import BSectionScroll from '@/components/section/scroll'
import BInfo from '@/components/base/info/index'
import BInfoItem from '@/components/base/info/info-item'
import BTable from '@/components/base/table/index'
import BForm from '@/components/base/form/index'
import BPagination from '@/components/base/pagination/index'
import BEmpty from '@/components/empty/index'
import BDialog from '@/components/base/dialog'
import CBtnTable from '@/components/btn/table'
import CBtnSearch from '@/components/btn/search'
import BBtnSearch from '@/components/btn/search-btn'
import BBtnCreate from '@/components/btn/create'
import BBtnInfo from '@/components/btn/info'
import BBtnUpdate from '@/components/btn/update'
import BBtnDelete from '@/components/btn/delete'
import BBtnConfirm from '@/components/btn/confirm'
import BBtnRefresh from '@/components/btn/refresh'
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)

Vue.component('TreeSelect', TreeSelect)
Vue.component('BPage', BPage)
Vue.component('BSectionScroll', BSectionScroll)
Vue.component('BInfo', BInfo)
Vue.component('BInfoItem', BInfoItem)
Vue.component('BTable', BTable)
Vue.component('BForm', BForm)
Vue.component('BPagination', BPagination)
Vue.component('BEmpty', BEmpty)
Vue.component('BDialog', BDialog)
Vue.component('CBtnTable', CBtnTable)
Vue.component('CBtnSearch', CBtnSearch)
Vue.component('BBtnSearch', BBtnSearch)
Vue.component('BBtnCreate', BBtnCreate)
Vue.component('BBtnInfo', BBtnInfo)
Vue.component('BBtnUpdate', BBtnUpdate)
Vue.component('BBtnDelete', BBtnDelete)
Vue.component('BBtnConfirm', BBtnConfirm)
Vue.component('BBtnRefresh', BBtnRefresh)
