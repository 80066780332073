/*
 * @Author: jiang
 * @Date: 2021-06-11 18:59:29
 * @Description:
 */

import request from '../axios'

//创建部门数据
export function DepartmentsDataStore(data) {
  return request({
    method: 'post',
    url: '/api/DepartmentsDataController',
    data,
  })
}

//修改部门数据
export function DepartmentsDataUpdate(params) {
  return request({
    method: 'post',
    url: `/api/DepartmentsDataUpdate`,
    data: params,
  })
}


//获取部门数据详情
export function DepartmentsDataSelect(data) {
  return request({
    method: 'post',
    url: '/api/DepartmentsDataSelect',
    data,
  })
}
