<template>
  <div>
    <el-button
      v-for="item in list"
      :key="item.id"
      :class="['c-btn-search', item.class]"
      :type="item.type"
      :icon="item.icon"
      @click="onClick(item.id)"
    >{{ item.text }}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Array,
      default: () => ['search', 'reset', 'create'],
    },
  },
  data() {
    return {
      btnList: [
        {
          id: 'search',
          class: 'search',
          type: 'primary',
          icon: 'el-icon-search',
          text: '查询',
        },
        {
          id: 'reset',
          class: 'reset',
          type: 'primary',
          icon: 'el-icon-refresh',
          text: '重置',
        },
        {
          id: 'create',
          class: '',
          type: 'primary',
          icon: 'el-icon-plus',
          text: '新增',
        },
      ],
    }
  },
  computed: {
    list() {
      const list = []
      this.show.forEach((item) => {
        let id
        let text
        if (typeof item === 'string') {
          id = item
        } else {
          id = item.id
          text = item.text
        }
        const row = this.btnList.find((it) => it.id === id)
        if (row) {
          if (text) {
            row.text = text
          }
          list.push(row)
        }
      })

      return list
    },
  },
  methods: {
    onClick(id) {
      this.$emit(id)
    },
  },
}
</script>
<style lang="scss" scoped>
@mixin c-btn-search($color) {
  color: #fff;
  background: $color;
  border-color: $color;

  &:hover,
  &:focus {
    background: lighten($color, 8%);
    border-color: lighten($color, 8%);
  }
}

.c-btn-search.search {
  @include c-btn-search(#1890ff);
}

.c-btn-search.reset {
  @include c-btn-search(#ffa940);
}
</style>
