/*
 * @Author: jiang
 * @Date: 2021-07-09 11:21:34
 * @Description: 全局过滤器
 */
import Vue from 'vue'
import { getDictLabel } from '@/utils'

/**
 * 通过 字典项的value查找对应的 label
 */
Vue.filter('dict', getDictLabel)
