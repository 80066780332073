<!--
 * @Author: jiang
 * @Date: 2021-08-05 11:05:35
 * @Description:
-->
<template>
  <el-button
    :type="type"
    :icon="icon"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>{{ text }}</slot>
  </el-button>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      id: 'save',
      type: 'primary',
      icon: 'el-icon-circle-check',
      text: '保存',
    }
  },
}
</script>
