<!--
 * @Author: jiang
 * @Date: 2021-08-05 11:05:35
 * @Description:
-->
<template>
  <el-button
    :type="type"
    :icon="icon"
    :class="['c-btn-search', 'search']"
    v-bind="$attrs"
    v-on="$listeners"
  >{{ text }}</el-button>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      id: 'search',
      type: 'primary',
      icon: 'el-icon-search',
      text: '查询',
    }
  },
}
</script>
<style lang="scss" scoped>
@mixin c-btn-search($color) {
  color: #fff;
  background: $color;
  border-color: $color;

  &:hover,
  &:focus {
    background: lighten($color, 8%);
    border-color: lighten($color, 8%);
  }
}

.c-btn-search.search {
  @include c-btn-search(#1890ff);
}

.c-btn-search.reset {
  @include c-btn-search(#ffa940);
}
</style>
