<!--
 * @Author: jiang
 * @Date: 2021-07-15 17:19:30
 * @Description:
-->
<template>
  <el-submenu
    :popper-append-to-body="toBody"
    v-if="data.list && data.list.length"
    :index="data.name"
  >
    <template slot="title">
      <!-- <i class="l-app-menu-icon el-icon-location"></i> -->
      <span>{{ data.title }}</span>
    </template>
    <app-menu-item
      v-for="item in data.list"
      :key="item.name"
      :toBody="toBody"
      :data="item"
    ></app-menu-item>
  </el-submenu>
  <el-menu-item
    v-else
    :index="data.name"
  >
    <!-- <i class="l-app-menu-icon el-icon-location"></i> -->
    <span>{{ data.title }}</span>
  </el-menu-item>
</template>

<script>
export default {
  name: 'AppMenuItem',
  props: {
    data: {
      type: Object,
    },
    toBody: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.l-app-menu-icon {
  margin-right: 3px;
  font-size: 16px;
  color: inherit;
}
</style>
