<!--
 * @Author: jiang
-->
<template>
  <div class="b-info-item">
    <div
      class="b-info-item__label"
      :style="{ width: labelWidth }"
    >{{ label }}：</div>
    <div class="b-info-item__value">
      <slot>
        {{ value }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelWidth: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
  },
}
</script>

<style lang="scss" scoped>
.b-info-item {
  position: relative;
  display: flex;
  padding: 10px;
  line-height: 20px;

  &::after {
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    content: '';
    border-bottom: 1px dashed #ecebeb;
  }

  .b-info-item__label {
    margin-right: 10px;
    font-size: 14px;
    color: #000;
    text-align: right;
  }

  .b-info-item__value {
    flex: 1;
    min-width: 0;
    font-size: 14px;
    color: #999;
    word-break: break-word;
  }
}
</style>
