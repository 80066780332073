/*
 * @Author: yangxin 396270694@qq.com
 * @Date: 2024-08-25 15:06:58
 * @LastEditors: yangxin 396270694@qq.com
 * @LastEditTime: 2024-08-25 18:26:22
 * @FilePath: \nmt-web-1\src\views\data\dataSources\jsonSources.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import gzap from "../mock/home/gzap.json"
import sczt from "../mock/home/sczt.json"
import yzt from "../mock/home/yzt.json"

export const jsonSources = {
  scztResultData :{
    type:"json",
    data:sczt,
    result:(res)=>{
      return res.data;
    }
  },
  yztResultDate :{
    type:"json",
    data:yzt,
    result:(res)=>{
      let data = res.data;
      const result = [];
      data.forEach(dept => {
        dept.list.forEach(item => {
          result.push({
            deptId: dept.deptId,
            deptName: dept.deptName,
            mapping: {
              "title": "title",
              "src": "fileContent",
              "date": "releaseTime",
              "source": "releaseBy"
          },
            ...item
          });
        });
      });
      return result;
    }
  },
  gzapData:{
    type:"json",
    data:gzap,
    result:(res)=>{
      return res.data.records;
    }
  },

};


