<!--
 * @Author: jiang
 * @Date: 2021-06-11 12:51:24
 * @Description: 系统标题
-->
<template>
  <div
    class="title-box"
    @click="$router.push({ name: 'index' })"
  >
    <div class="logo"></div>
    <div class="text">精准监督</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  .logo {
    width: 48px;
    height: 13px;
    background: url('~@/assets/images/logo.png') center / cover no-repeat;
  }

  .text {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #15151f;
  }
}
</style>
