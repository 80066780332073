<template>
  <div>
    <el-tooltip
      v-for="item in list"
      :key="item.id"
      :content="item.text"
      placement="top"
    >
      <el-button
        class="c-btn-table"
        :type="item.type"
        :icon="item.icon"
        plain
        @click="onClick(item.id)"
      ></el-button>
    </el-tooltip>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Array,
      default: () => ['info', 'update', 'delete'],
    },
  },
  data() {
    return {
      btnList: [
        {
          id: 'info',
          type: 'primary',
          icon: 'el-icon-view',
          text: '查看',
        },
        {
          id: 'update',
          type: 'primary',
          icon: 'el-icon-edit',
          text: '编辑',
        },
        {
          id: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
          text: '删除',
        },
      ],
    }
  },
  computed: {
    list() {
      const list = []
      this.show.forEach(item => {
        const row = this.btnList.find(it => it.id === item)
        if (row) {
          list.push(row)
        }
      })

      return list
    },
  },
  methods: {
    onClick(id) {
      this.$emit(id)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-btn-table {
  padding: 10px 12px;
}
</style>
