<template>
  <div class="default-container">
    <page-header v-if="showHeader"></page-header>
    <page-tag v-if="showTag"></page-tag>
    <div class="default-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import PageHeader from './components/page-header'
import PageTag from './components/page-tag'

export default {
  components: {
    PageHeader,
    PageTag,
  },
  computed: {
    routeMeta() {
      return this.$route.meta
    },
    showHeader() {
      return this.$route.meta.pageHeader !== false
    },
    showTag() {
      return this.$route.meta.pageTag !== false
    },
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>


.default-container {
  display: flex;
  flex-direction: column;
  // overflow-y: hidden; /* 禁止垂直滚动 */
  // height: 80vh; /* 或其他固定高度 */
  width: 100%; /* 或其他宽度 */
  // min-width: 1300px;
  // height: 100vh;
  // min-height: 740px;
  background-color: #f4f5f7;

  .default-main {
    flex: 1;
    min-height: 80vh;
    // overflow-x: scroll;
    // overflow: hidden;
  }


}
</style>
