import rfdc from 'rfdc'

/**
 * proto true 会把拷贝原型对象上的属性
 * @param { Object } target - 目标对象
 */
const copy = rfdc({ proto: true, circles: false })

export default copy
// const rfdc = require('rfdc')

// const copy = rfdc({ proto: true, circles: false })

// const a = {
//   a: 1222,
//   b: undefined
// }
// const b = copy(a)
// a.a = 4333
// console.log(b, a)
