<template>
  <normal-layout></normal-layout>
</template>
<script>
import NormalLayout from '@/layouts/default'
import 'animate.css'
export default {
  components: {
    NormalLayout,
  },
}
</script>
<style lang="scss">
@import '~@/styles/global.scss';
</style>
