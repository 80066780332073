/*
 * @Author: jiang
 * @Date: 2021-06-10 21:16:21
 * @Description: 菜单相关 list collapse
 */
import { resetRouter } from '@/router'
import routes from '@/router/routes'
import { listToTree, treeFind, treeFilter } from '@/utils/tree'
import { getLocal, setLocal } from '@/store/storage'

// routes store menu/data
const menuTreeData = handlerCreateMenuTree(getLocal('menu/data'), getLocal('user_info').id)
handlerAddRoute(getLocal('menu/data'), getLocal('user_info').id)

const menu = {
  namespaced: true,
  state: {
    treeData: menuTreeData,
    parentName: null,
    collapse: false,
  },
  getters: {
    menuTree: state => {
      let tree
      if (state.parentName) {
        tree =
          treeFind(
            state.treeData,
            node => {
              return node.name === state.parentName
            },
            { children: 'list' },
          )?.list ?? []
      } else {
        tree = state.treeData
      }
      return tree
    },
    menuShow(state, getters) {
      return getters.menuTree.length > 0
    },
  },
  mutations: {
    TOGGLE_COLLAPSE: state => {
      state.collapse = !state.collapse
    },
    SET_PARENT(state, data) {
      state.parentName = data
    },
    SET_MENU(state, data) {
      state.treeData = data
    },
    CREATE_MENU() {},
    CLEAR(state) {},
  },
  actions: {
    GET_MENU({ commit, rootState }) {
      const menus = handlerCreateMenuTree(routes, rootState.user.userInfo.id)
      commit('SET_MENU', menus)
      setLocal('menu/data', routes)
      handlerAddRoute(routes, rootState.user.userInfo.id)
    },
  },
}

/**
 * 生成菜单
 */
function handlerCreateMenuTree(routes, id) {
  id = id + ''
  // console.log('id', id)
  const menuList = routes
    .filter(item => {
      let flag = true
      if (!['menu', 'page'].includes(item.type)) {
        flag = false
      }

      if (!item.menu) {
        flag = false
      }
      const navId = item.meta?.navId
      if (navId && typeof navId === 'string') {
        if (navId !== id) {
          flag = false
        }
      } else if (navId && Array.isArray(navId)) {
        if (!navId.includes(id)) {
          flag = false
        }
      }
      return flag
    })
    .map(item => {
      return {
        path: item.path,
        name: item.name,
        alias: item.alias,
        parent: item.parent,
        type: item.type,
        menu: item.menu,
        title: item.title,
        order: item.order,
        meta: item.meta,
        query: item.query,
      }
    })
  // menuList.sort((a, b) => {
  //   if (a.meta.order && b.meta.order) {
  //     return a.meta.order - b.meta.order
  //   } else {
  //     return -1
  //   }
  // })
  const tree = listToTree(menuList, { parentId: 'parent', id: 'name', children: 'list' })

  /**
   * 过滤掉没有子项的目录
   */
  const treeRes = treeFilter(
    tree,
    node => {
      if (node.type === 'menu') {
        if (!node.list || (node.list && node.list.length === 0)) {
          return false
        }
      }
      return true
    },
    { children: 'list' },
  )
  return treeRes
}

/**
 * 添加动态路由
 */
function handlerAddRoute(routes, id) {
  const dynamicRoutes = routes
    .filter(item => {
      return item.type === 'page'
    })
    .map(item => {
      return {
        path: item.path,
        name: item.name,
        component: item.component,
        meta: {
          parent: item.parent,
          menu: item.menu,
          title: item.title,
          query: item.query,
        },
      }
    })

  resetRouter(dynamicRoutes)
}

export default menu
