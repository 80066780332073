<template>
  <div :class="{'b-scroll': true, 'b-scroll__y' : direction === 'y', 'b-scroll__x': direction=== 'x'}">
    <div class="b-scroll__header">
      <slot name="header">
        <div
          v-if="title"
          class="title"
        >
          <span>{{ title}}</span>
          <el-button
            type="text"
            v-if="$listeners.more"
            @click="$emit('more')"
          >{{ moreText }}</el-button>
        </div>
      </slot>
    </div>
    <div class="b-scroll__body">
      <el-scrollbar
        style="height: 100%;"
        :wrap-style="scrollWrapStyle"
        :view-style="{padding: '5px 15px'}"
      >
        <slot></slot>
      </el-scrollbar>
    </div>
    <div class="b-scroll__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    direction: {
      type: String,
      default: 'y',
    },
    moreText: {
      type: String,
      default: '查看更多',
    },
  },
  computed: {
    scrollWrapStyle() {
      const style = {}

      if (this.direction === 'y') {
        style['overflow-x'] = 'hidden'
        style['overflow-y'] = 'scroll'
      }

      // console.log(style)

      return style
    },
  },
}
</script>
<style lang="scss" scoped>
.b-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .b-scroll__header {
    padding: 0 15px;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #2b2d42;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 16px;
        margin-top: -8px;
        content: '';
        background: #04a37e;
        border-radius: 2px;
      }
    }
  }

  .b-scroll__body {
    flex: 1;
    min-width: 0;
    min-height: 0;
  }

  &.b-scroll__y /deep/ .el-scrollbar {
    height: 100%;

    .el-scrollbar__wrap {
      overflow: hidden scroll;
    }

    .el-scrollbar__view {
      overflow-x: hidden;
    }
  }

  // .b-scroll__footer {}
}
</style>
