<!--
 * @Author: jiang
 * @Date: 2021-06-11 14:00:12
 * @Description: 搜索框
-->
<template>
  <div class="search-box">
    <el-input
      v-model="value"
      placeholder="输入内容"
    ></el-input>
    <el-button
      icon="el-icon-search"
      @click="onSearch"
    ></el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
    }
  },
  methods: {
    onSearch() {},
  },
}
</script>

<style lang="scss" scoped>
.search-box {
  position: relative;
  width: 200px;

  /deep/ .el-input__inner {
    padding-right: 40px;
    background: rgba($color: #dfe3e8, $alpha: 0.6);
    border-radius: 15px;
  }

  .el-button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
  }
}
</style>
