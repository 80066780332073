import Vue from 'vue'
import router from './router'
import store from './store'
import './plugin/index'
import './utils/validator'
import './router/guard'
import App from './App.vue'

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import dataV from '@jiaminghi/data-view'

import Fragment from 'vue-fragment';

Vue.use(Fragment.Plugin);

Vue.use(dataV)


//localStorage.setItem('user_info', {'api_token':"18865bb48c9e83e1"});

Vue.config.productionTip = false

/* eslint-disable */
var _hmt = _hmt || []
window._hmt = _hmt
;(function() {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?36a9b171904cb0d8ca822b7342175d28'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
