/*
 * @Author: jiang
 * @Date: 2021-07-09 11:40:32
 * @Description:
 */
import store from '@/store/index'

/**
 * 字典查找 通过value 和 字典key（或者数组项）查找对应的 label
 */
export function getDictLabel(val, key, prop = { value: 'value', label: 'label' }) {
  if (val === undefined || val === null) {
    console.log('无效的过滤器内容')
    return val
  } else if (typeof key === 'string') {
    // console.log(store.state)
    const dict = store.state.dictList.find(item => item.key === key)
    if (!dict) {
      console.log('没有在字典列表中找到对应的 key: ' + key)
      return val
    }

    const item = dict.items.find(item => item[prop.value] === val)
    if (!item) {
      console.log(`没有在字典[${dict.name}-${dict.key}]中找到对应的值：${val}`)
      return val
    }

    return item[prop.label]
  } else if (Array.isArray(key)) {
    const item = key.find(item => item[prop.value] === val)
    if (!item) {
      console.log(`没有自定义列表中找到对应的值：${val}`)
      return val
    }

    return item[prop.label]
  }
}

/**
 * 通过字典 key 查找对应的字典项列表
 */
export function getDictItems(key) {
  const dict = store.state.dictList.find(item => item.key === key)
  if (!dict) {
    console.log('没有在字典列表中找到对应的 key: ' + key)
    return []
  }

  return dict.items
}

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, L => L.toUpperCase())
}

export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str)
}
 


export function makeMap(str, expectsLowerCase) {
  const map = Object.create(null)
  const list = str.split(',')
  for (let i = 0; i < list.length; i++) {
    map[list[i]] = true
  }
  return expectsLowerCase
    ? val => map[val.toLowerCase()]
    : val => map[val]
}

export const exportDefault = 'export default '
export const beautifierConf = {
  html: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'separate',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
}